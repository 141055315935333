import React from "react";
import SuccessIndicator from "react-success-indicator";
import { Box, Flex } from "@rebass/grid";
import styled from "styled-components";
import Template from "../Template";
import successImage from '../../resources/images/success.png'

const Title = styled.h1`
  margin-bottom: 1rem;
  font-weight: 300;
  font-size: 1.5rem;
`;

const SuccessMessage = styled.p`
  font-weight: 300;
`;

function Success({ email, sendAgain }) {
  return (
    <Template
      content={
        <Flex flexDirection="column" justifyContent="center">
          <Box mb={4}>
            <img src={successImage} style={{maxWidth: 350}}/>
          </Box>

          <Title>Indicação Enviada!</Title>
          <SuccessMessage>
            Sua indicação para <b>{email}</b> foi enviada
          </SuccessMessage>
        </Flex>
      }
      actionLabel="Indicar Outro"
      action={sendAgain}
    />
  );
}

export default Success;
