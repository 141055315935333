import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  height: 150vh;
  flex: 1;
  align-items: center;
  justify-content: center;
  background: #fff;
  position: absolute;
  width: 100%;
`;

export const Message = styled.div`
  margin-left: 1rem;
`;
