import React, { useState, useEffect } from "react";
import { Box, Flex } from "@rebass/grid";
import styled from "styled-components";
import Template from "../Template";
import Button from "../Button";
import errorImage from "../../resources/images/error.png"
import mainImage from "../../resources/images/main.png"

const Input = styled.input`
  width: 100%;
  font-size: 1rem;
  border: none;
  height: 80px;
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: 2rem;
  text-align: center;
  
  &:focus {
    outline: none;
  }
`;

const Title = styled.h1`
  font-weight: 300;
  text-align: left;
  font-size: 1.25rem;
`;

function Form({ submit, email, setEmail, emailError, error }) {
  return (
    <Flex p={3} flexDirection="column" alignItems="center" style={{ height: "100%" }}>
        {
          !error ? (
            <img src={mainImage} style={{
              width: 350,
              marginBottom: 40
            }}/>
          ) : (
            <img src={errorImage} style={{
              width: 200
            }}/>
          )
        }
      <Flex flexDirection="column" alignItems="center">
        <Title>Informe o e-mail de quem voce deseja indicar.</Title>
        <Box style={{color: 'grey', fontSize: '15px', minWidth: '250px', maxWidth: '420px',  'margin-top': '30px'}}>
            Indique um amigo para ser parceiro moObie e, quando ele finalizar a primeira reserva, você e ele ganham um bônus de R$50 cada*
        </Box>
        <Box>
          <Input
            flex="1"
            type="email"
            autoFocus={true}
            value={email}
            onChange={setEmail}
          />
      
          {error && (
            <Box
              style={{ fontSize: "14px", color: "#7b7b7b", textAlign: "center" }}
            >
              Ocorreu um erro.<b> {error}</b>.
            </Box>
          )}

          {emailError && (
            <Box
              style={{ fontSize: "14px", color: "#7b7b7b", textAlign: "center" }}
            >
              Informe um endereço de e-mail <b>válido</b>.
            </Box>
          )}

          <Button
            onClick={submit}
            style={{ textAlign: "center" }}
          >
            Enviar
          </Button>
        </Box>
        <Box style={{color: 'grey', fontSize: '13px', minWidth: '250px', maxWidth: '420px', 'margin-top': '10px'}}>
            *seu bônus só será válido caso você tenha um carro disponível para locação na plataforma.
        </Box>
      </Flex>
    </Flex>
  );
}

export default Form;
