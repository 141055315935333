import React from "react";
import { Flex } from "@rebass/grid";
import styled from "styled-components";
import Button from "../Button";

const Container = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  background: #fff;
`;

function Template({ content, action, actionLabel }) {
  return (
    <Container>
      <Flex>
        {content}
      </Flex>

      {action && <Button onClick={action}>{actionLabel}</Button>}
    </Container>
  );
}

export default Template;
