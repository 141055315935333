import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    @import url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700&display=swap");

    * {
        margin: 0;
        padding: 0
    }

    body {
    font-family: "Roboto", sans-serif;
    }

    html, body, #root, main {
        height: 100%;
        box-sizing: border-box;
    }
`;
