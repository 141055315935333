import React, { useState } from "react";
import styled from "styled-components";

const Container = styled.button`
  padding: 2rem;
  color: #6f6ca2;
  font-weight: bold;
  font-size: 1rem;
  margin: 0 auto;
  width: 100%;
  border: none;
  background-color: transparent;

  &:focus {
    outline: none;
  }
`;

function Button(props) {
  return <Container {...props}>{props.children}</Container>;
}

export default Button;
