import React from "react";
import Loader from "react-loader-spinner";

// Styles
import { Wrapper, Message } from "./Loader.styles";

function LoaderComponent({ message }) {
  return (
    <Wrapper>
      <Loader type="TailSpin" color="#6f6ca2" height="60" width="60" />
      <Message>{message}</Message>
    </Wrapper>
  );
}

export default LoaderComponent;
