import React, { useState, useEffect } from "react";
import Styles from "./styles";
import Success from "./components/Success";
import Form from "./components/Form";
import Loader from "./components/Loader";
import qs from "query-string";

function App() {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    setEmail("");
    getUserIdFromURL();
  }, []);

  const getUserIdFromURL = () => {
    const { userId } = qs.parse(window.location.search);
    setUserId(userId);
  };

  const validEmail = email => {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );
  };

  const handleSubmit = () => {
    if (!validEmail(email)) {
      return setEmailError(true);
    } 

    setLoading(true);
    setError(false);

    fetch(`https://mgm.moobie.com.br/referrals/${userId}`, {
      method: "POST",
      mode: 'cors',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ email })
    })
    .then(({status}) => {
      if (status === 422) {
        return setError('Email já indicado!')
      }
      if (status !== 204) {
        throw new Error()
      }
      setSuccess(true);
    })
    .catch(error => {
      setError('Tente novamente!');
    })
    .then(() => {
      setLoading(false);
    });
  };

  const handleSendAgain = () => {
    setSuccess(false);
    setEmail("");
  };

  const handleChangeEmail = e => {
    setEmailError(false);
    setEmail(e.target.value);
  };

  return (
    <main>
      <Styles />

      {loading && <Loader />}

      {success ? (
        <Success 
          email={email}
          sendAgain={handleSendAgain} 
        />
      ) : (        
        <Form
          email={email}
          setEmail={handleChangeEmail}
          submit={handleSubmit}
          emailError={emailError}
          error={error}
        />
      )}
    </main>
  );
}

export default App;
